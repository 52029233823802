import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { ArrowLeft, X } from "react-feather";
import { useTranslation } from "react-i18next";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  XIcon,
} from "react-share";
import Helper from "src/utils/Helper";

const SocialShare = ({ bookingURL, close, onChangeCategory }) => {
  const { t } = useTranslation();
  const isMobile = Helper.IsMobile();

  return (
    <Box>
      <Box
        sx={{
          p: "20px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <IconButton onClick={() => onChangeCategory("booking")}>
              <ArrowLeft size={20} />
            </IconButton>
          </Box>

          <Box>
            <IconButton onClick={() => close()}>
              <X size={20} />
            </IconButton>
          </Box>
        </Box>

        <Box sx={{ mt: "4px" }}>
          <Typography
            sx={{
              fontFamily: "Mukta",
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "26px",
              lineHeight: "26px",
              letterSpacing: "-0.01em",
              color: "#000000",
              textAlign: "center",
            }}
          >
            {t("package.label.booking.share_on_social")}
          </Typography>
        </Box>

        <Box sx={{ mt: "8px" }}>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "18px",
              textAlign: "center",
              color: "#6E757C",
            }}
          >
            {t("package.label.booking.share_on_social.description")}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          px: "40px",
          mt: "12px",
          pb: "40px",
          display: "flex",
          gap: "24px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          <FacebookShareButton
            url={bookingURL}
            quote={"Treffas AB"}
            // hashtag="#TreffasAB"
          >
            <FacebookIcon size={isMobile ? 50 : 60} round={true} />
          </FacebookShareButton>
        </Box>

        <Box>
          <LinkedinShareButton url={bookingURL} title={"Treffas AB"}>
            <LinkedinIcon size={isMobile ? 50 : 60} round={true} />
          </LinkedinShareButton>
        </Box>

        <Box>
          <TwitterShareButton url={bookingURL} title={"Treffas AB"}>
            <XIcon size={isMobile ? 50 : 60} round={true} />
          </TwitterShareButton>
        </Box>
      </Box>
    </Box>
  );
};

export default SocialShare;
