import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

const TreffasSwicth = styled(Switch)(({ theme }) => ({
  width: 35,
  height: 20,
  padding: 0,
  borderRadius: 15,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 18,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(14px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "var(--bg-black)",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 17,
    height: 17,
    borderRadius: "50%",
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "#EAECEF",
    boxSizing: "border-box",
  },
}));

export default TreffasSwicth;

// "& .MuiSwitch-switchBase": {
//   transform: "translate(-6px, -6.5px)",
//   color: "#FFF",
//   "& + .MuiSwitch-track": {
//     opacity: 1,
//     backgroundColor: "#EAECEF",
//   },

//   "&.Mui-checked": {
//     transform: "translate(13px, -7px)",
//     color: "#FFF",
//     "& + .MuiSwitch-track": {
//       opacity: 1,
//       backgroundColor: "#0173FE",
//     },
//   },
// },
