import { Avatar, Box, IconButton, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { ArrowLeft, Calendar, Clock, Globe, User } from "react-feather";
import DescTooltip from "./DescTooltip";
import { useTranslation } from "react-i18next";
import Constant from "src/utils/Constant";
import TreffasLogo from "./TreffasLogo";

const coachCustomPage = process.env.REACT_APP_BLOG_URL;

const BookingHeader = ({
  selectedOffer,
  selectedDate,
  selectedSessionTime,
  handleBack,
  showBackButton = true,
  coachDetails,
}) => {
  const { t } = useTranslation();
  const modeOfPayment =
    selectedOffer?.custom_options?.paypal_config?.mode === "payment"
      ? t("individual.booking.header.one_time_payment")
      : selectedOffer?.custom_options?.stripe_config?.mode === "payment"
      ? t("individual.booking.header.one_time_payment")
      : t("individual.booking.header.subscription");

  let meetingLocation = selectedOffer?.custom_options?.meeting_location
    ? `Location: ${selectedOffer?.custom_options?.meeting_location} `
    : "";

  const tooltipDescription = `${selectedOffer?.description} \n ${meetingLocation}`;

  const personalLink = coachDetails?.onboarding?.personal_link;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: { xs: "20px", md: "37px" },
          minHeight: { xs: "100%" },
          backgroundColor: "#FFFFFF",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: { xs: "flex-start", md: "center" },
          alignItems: { xs: "flex-start", md: "center" },
          py: "10px",
          borderBottom: "1px solid #F4F5F6",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "flex-start", sm: "space-between" },
            alignItems: { xs: "center", sm: "center" },
            width: { xs: "100%", md: "840px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              px: { xs: "23px", md: "0px" },
              cursor: "pointer",
            }}
            onClick={() => {
              window.open(`${coachCustomPage}/${personalLink}`, "_blank");
            }}
          >
            <Box>
              <Avatar
                alt={coachDetails?.name?.charAt().toUpperCase()}
                sx={{
                  height: "30px",
                  width: "30px",
                  ...(!coachDetails?.avatar && {
                    backgroundColor: "#7B1FA2",
                    fontSize: "15px",
                    fontWeight: 600,
                  }),
                }}
                src={Constant.imageUrl("coach", coachDetails?.avatar)}
              />
            </Box>

            <Box>
              <Typography
                sx={{
                  fontWeight: 500,
                  color: "#353A3F",
                  fontSize: "14px",
                  lineHeight: "18px",
                  textTransform: "capitalize",
                }}
              >
                {coachDetails?.name}
              </Typography>
            </Box>
          </Box>

          <Box>
            <TreffasLogo />
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: { xs: "20px", md: "37px" },
          minHeight: { xs: "100%" },
          backgroundColor: "#FFFFFF",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: { xs: "flex-start", md: "center" },
          alignItems: { xs: "flex-start", md: "center" },
          p: { xs: "23px", md: "23px 0px" },
          borderBottom: "1px solid #F4F5F6",
        }}
      >
        <Box>
          {showBackButton && (
            <IconButton
              sx={{
                border: "1px solid #E0E0E0",
              }}
              onClick={() => handleBack()}
            >
              <ArrowLeft />
            </IconButton>
          )}
        </Box>

        <Box
          sx={{
            width: { xs: "100%", md: "840px" },
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: { xs: "flex-start", md: "space-between" },
            alignItems: { xs: "flex-start", md: "center" },
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "520px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "12px",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  minWidth: { xs: "100%", md: "550px" },
                  display: "flex",
                  gap: "11px",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "Mukta",
                      fontWeight: 700,
                      fontSize: "24px",
                      lineHeight: "26px",
                      letterSpacing: "-0.01em",
                    }}
                  >
                    {selectedOffer?.name}
                  </Typography>
                </Box>

                <Box sx={{ width: "30px" }}>
                  <DescTooltip
                    title={selectedOffer?.name}
                    description={tooltipDescription}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "3px 12px",
                    gap: "4px",
                    border: "1px solid #DBDBDB",
                    borderRadius: "13px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "12px",
                      lineHeight: "16px",
                      color: "#353A3F",
                    }}
                  >
                    {Number(selectedOffer?.price) > 0
                      ? `${
                          Constant.getCurrency(
                            selectedOffer?.custom_options?.currency ?? "USD"
                          ).symbols
                        }${selectedOffer?.price} - ${modeOfPayment}`
                      : t("individual.booking.header.free")}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "3px 12px",
                    gap: "4px",
                    border: "1px solid #DBDBDB",
                    borderRadius: "13px",
                  }}
                >
                  <Clock color={"#878787"} size={12} />
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "12px",
                      lineHeight: "16px",
                      color: "#353A3F",
                    }}
                  >
                    {selectedOffer?.duration}{" "}
                    {t("individual.booking.header.min")}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: { xs: "none", sm: "flex" },
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "3px 12px",
                    gap: "4px",
                    border: "1px solid #DBDBDB",
                    borderRadius: "13px",
                  }}
                >
                  <User color={"#878787"} size={12} />
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "12px",
                      lineHeight: "16px",
                      color: "#353A3F",
                    }}
                  >
                    {selectedOffer?.custom_options?.sessions?.configuration
                      ?.type === "group"
                      ? t("individual.booking.header.group")
                      : t("individual.booking.header.one_on_one")}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: { xs: "none", sm: "flex" },
                    // display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "3px 12px",
                    gap: "4px",
                    border: "1px solid #DBDBDB",
                    borderRadius: "13px",
                  }}
                >
                  <Globe color={"#878787"} size={12} />
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "12px",
                      lineHeight: "16px",
                      color: "#353A3F",
                    }}
                  >
                    {selectedOffer?.custom_options?.meeting_type ===
                    "onsite-meeting"
                      ? t("individual.booking.header.onsite")
                      : t("individual.booking.header.virtual")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: { xs: "100%", md: "200px" },
              mt: { xs: "24px", md: "0px" },
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontFamily: "Mukta",
                  fontWeight: 700,
                  fontSize: "11px",
                  lineHeight: "14px",
                  letterSpacing: "0.07em",
                  color: "#A1A1A1",
                  textAlign: { xs: "left", md: "center" },
                }}
              >
                {t("individual.booking.header.your_schedule")}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "6px 10px",
                gap: "10px",
                width: { xs: "100%", md: "200px" },
                background: "#F2F2F2",
                borderRadius: "6px",
                mt: "6px",
              }}
            >
              <Box>
                <Calendar color={"#878787"} size={12} />
              </Box>

              <Box>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: selectedDate ? "#19181B" : "#878787",
                  }}
                >
                  {selectedDate
                    ? moment(new Date(selectedDate)).format(
                        "dddd,  MMM. DD, YYYY"
                      )
                    : t("individual.booking.header.select_date")}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "6px 10px",
                gap: "10px",
                width: { xs: "100%", md: "200px" },
                background: "#F2F2F2",
                borderRadius: "6px",
                mt: "6px",
              }}
            >
              <Box>
                <Clock color={"#878787"} size={12} />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: selectedSessionTime?.start ? "#19181B" : "#878787",
                  }}
                >
                  {selectedSessionTime?.start ? (
                    <>
                      {moment(new Date(selectedSessionTime?.start)).format(
                        "hh:mm A"
                      )}{" "}
                      &#x2192;{" "}
                      {moment(new Date(selectedSessionTime?.start))
                        .add(selectedOffer.duration, "minutes")
                        .format("hh:mm A")}
                    </>
                  ) : (
                    t("individual.booking.header.select_time")
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BookingHeader;
