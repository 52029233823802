import {
  IconButton,
  Box,
  MenuItem,
  Typography,
  Drawer,
  Badge,
} from "@mui/material";
import Logo from "src/components/Logo";
import { ChevronDown, X } from "react-feather";
import MenuIcon from "@mui/icons-material/Menu";

import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

const TopbarMobile = ({
  handleOpenNavMenu,
  anchorElNav,
  handleCloseNavMenu,
  GuardedRoutes,
  setMobileAnchorElSubRoute,
  setMobileAnchorElSubRouteKey,
  navigate,
  mobileAnchorElSubRouteKey,
  mobileAnchorElSubRoute,
  notificationCount,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="primary"
      >
        <MenuIcon sx={{ fill: "#000" }} />
      </IconButton>

      <Box
        sx={{
          display: { xs: "flex", lg: "none" },
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Logo />
      </Box>

      <Drawer
        anchor={"left"}
        open={Boolean(anchorElNav)}
        onClose={() => handleCloseNavMenu()}
        PaperProps={{
          sx: {
            width: "100%",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: "29px",
            pt: "66px",
          }}
        >
          <Box>
            <Logo />
          </Box>

          <Box>
            <IconButton
              sx={{ border: "1px solid #DFE2E6" }}
              onClick={() => handleCloseNavMenu()}
            >
              <X size={18} />
            </IconButton>
          </Box>
        </Box>

        <Box sx={{ mt: "40px" }}>
          {GuardedRoutes.filter((page) => page.show).map((page, key) => {
            let path = page.path.replace("*", "");
            let selected = window.location.pathname === page.path;
            let __count = GuardedRoutes.filter((page) => page.show).length;
            return (
              <Fragment key={key}>
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    if (page.withSubs) {
                      setMobileAnchorElSubRoute(e.currentTarget);
                      setMobileAnchorElSubRouteKey(key);
                      return;
                    }
                    navigate(path);
                    handleCloseNavMenu();
                  }}
                  sx={{
                    margin: 0,
                    padding: 0,
                    px: "24px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      height: "62px",
                      borderBottom:
                        key + 1 !== __count ? "1px solid #F4F5F6" : "none",
                      ...(selected && {
                        backgroundColor: "#F4F5F6",
                        borderRadius: "8px",
                      }),
                      px: "16px",
                    }}
                  >
                    <Box flexGrow={1}>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "18px",
                          color: selected ? "#19181B" : "#6E757C",
                        }}
                      >
                        {t(page.name)}
                      </Typography>
                    </Box>

                    <Box pt={"5px"}>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "18px",
                          color: "#060A2D",
                        }}
                      >
                        {page.withSubs && <ChevronDown size={13} />}
                      </Typography>
                    </Box>
                  </Box>
                </MenuItem>

                <Box>
                  {page.withSubs &&
                    mobileAnchorElSubRouteKey === key &&
                    Boolean(mobileAnchorElSubRoute) &&
                    page.routes
                      .filter((page) => page.show !== false)
                      .map((subPage) => {
                        let __isActive = window.location.pathname.includes(
                          `${path.replace("*", "")}${subPage.path}`
                        );

                        return (
                          <Box key={subPage.name}>
                            <MenuItem
                              onClick={() => {
                                setMobileAnchorElSubRoute(null);
                                navigate(
                                  `${path.replace("*", "")}${subPage.path}`
                                );
                                handleCloseNavMenu();
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  width: "100%",
                                  height: "50px",
                                  px: "40px",
                                  ...(__isActive && {
                                    backgroundColor: "#F4F5F6",
                                    borderRadius: "8px",
                                  }),
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    lineHeight: "18px",
                                    alignItems: "center",
                                    color: __isActive ? "#19181B" : "#6E757C",
                                  }}
                                >
                                  {t(subPage.name)}
                                </Typography>
                              </Box>
                            </MenuItem>
                          </Box>
                        );
                      })}
                </Box>
              </Fragment>
            );
          })}

          {/* notifications */}
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: "62px",
                px: "16px",
              }}
            >
              <MenuItem
                onClick={(e) => {
                  navigate("/app/notifications");
                  handleCloseNavMenu();
                }}
                sx={{
                  margin: 0,
                  padding: 0,
                  px: "24px",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "18px",
                    color: "#6E757C",
                  }}
                >
                  Notifications
                </Typography>

                <Badge badgeContent={notificationCount} color="success"></Badge>
              </MenuItem>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default TopbarMobile;
